<template>
  <card class="dashboard-margin" header-classes="border-radius-top-stevie" body-classes="pt-0">
      <div class="row align-items-center pt-4">
        <div class="col-12 pt-4">
          <h3 class="mb-2">{{ $t("mailsSent") }}</h3>
        </div>
      </div>
    <div class="col-12 col-sm-6 col-md-8 text-left pb-2 pb-sm-0 pl-0">
          <base-button
              class="p-0 back-button d-flex justify-content-between px-2"
              @click="backToList"
            >
              <div class="d-flex justify-content-center align-items-center">
                <img
                  src="/img/icons/stevie/back-shape.svg"
                  class="rotateArrow mr--2"
                  alt="Back icon"
                />
                <img
                  src="/img/icons/stevie/back-shape.svg"
                  class="rotateArrow mr-2"
                  alt="Back icon"
                />
                {{ $t("allItems", [$tc("mail", 2)]) }}
              </div>
            </base-button>
        </div>
    <el-table
      class="table-responsive table-flush border-top-0 min-table"
      :data="contactsData.data"
    >
    <el-table-column :label="$t('title')" prop="title" min-width="90px" label-class-name="border-top-0 text-prussian-blue">
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <div class="media-body w-100 text-truncate">
              <span class="font-weight-500 name mb-0 text-sm text-dark">{{
                row.title
                }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('name')" prop="name" min-width="150px" label-class-name="border-top-0 text-prussian-blue">
        <template v-slot="{ row }">
          <div v-if="row.name" class="media align-items-center">
            <div class="media-body w-100">
              <span class="font-weight-500 name mb-0 text-sm text-dark">{{
                    row.name
                }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('lastName')" prop="lastname" min-width="200px" label-class-name="border-top-0 text-prussian-blue">
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <div class="media-body w-100 text-truncate">
              <span class="font-weight-500 name mb-0 text-sm text-dark">{{
                row.lastname
                }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('email')" prop="email" min-width="200px" label-class-name="border-top-0 text-prussian-blue">
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <div class="media-body w-100 text-truncate">
              <span class="font-weight-500 name mb-0 text-sm text-dark">{{
                row.email
                }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('dateSent')" prop="sent_at" min-width="200px" label-class-name="border-top-0 text-prussian-blue">
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <div class="media-body w-100 text-truncate">
              <span class="font-weight-500 name mb-0 text-sm text-dark">{{
                row.pivot.sent_at ? row.pivot.sent_at : $t('notSent')
                }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      
    </el-table>

    <div class="card-footer py-4 d-flex justify-content-end">
      <base-pagination :modelValue="contactsData.current_page" @update:modelValue="changePage" :total="contactsData.total" :perPage="contactsData.per_page"></base-pagination>
    </div>
    </card>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElSelect,
  ElOption
} from "element-plus";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import useMails from "@/composables/useMails";

export default {
  name: "MailsSent",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    ElSelect,
    ElOption,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();    
    
    const contactsData = ref({
        data:[]
    })

    const { getContactsSent }= useMails();

    const isLoading = ref(false)

    const changePage = (event)=>{
      if(event !== contactsData.value.current_page){
        listContacts(event)
      }
    }

    const listContacts = async (page = 1) => {
      let response = await getContactsSent(route.params.id, page);
      if(response && response.status === 200){
        contactsData.value = response.data;
      }
    }
    const backToList = () => {
      const savedFilters = JSON.parse(localStorage.getItem('mailsFilters'))

      router.push({ name: "MailsList",  query: savedFilters});
    };
    onMounted(()=>{
        contactsData.value.current_page = 1;
        listContacts(contactsData.value.current_page)
    })

    return {
      contactsData,
      isLoading,
      listContacts,
      changePage,
      backToList
    };
  },
}
</script>

<style scoped lang="scss">
.el-table_1_column_4 th {
  text-align: right;
}
:deep() .el-table__body-wrapper{
  min-height: 350px!important;
}
.min-table{
  min-height: 450px;
  max-width: 99%!important;
}
.dropdown-item{
  height: 33px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:hover{
    color: #16181b!important;
    background-color: #f6f9fc!important;
  }
}

</style>
